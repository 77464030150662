<template>
    <div>
        <a href="javascript:void(0);" class="floating_button" @click="click">
            <i class="fa fa-plus my-float"></i>
        </a>
    </div>
</template>

<script>
    export default {
        name: "FloatingButton",
        methods: {
            click(){
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>
    .floating_button {
        position:fixed;
        width:40px;
        height:40px;
        bottom:20px;
        right:20px;
        background-color: #23BF08;
        color:#FFF;
        border-radius:30px;
        text-align:center;
        box-shadow: 2px 2px 3px #000000;
        font-size: 14px;
    }
    .my-float{
        margin-top:12px;
    }
</style>
